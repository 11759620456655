import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ApiService } from 'src/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewEncapsulation } from '@angular/core';
import { letters } from './letters-array';
import {FormGroup, FormControl} from '@angular/forms';
import SearchParams from '../../models/SearchParams'
import BatchParams from 'src/models/BatchParams';
import { last } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ReplaySubject, Subject } from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import { PdfDialogComponent } from '../pdf-dialog/pdf-dialog.component';
import { DocumentDetailsComponent } from '../document-details/document-details.component';
import { DeleteComponent } from '../delete/delete.component';
import { DeleteBatchComponent } from '../delete-batch/delete-batch.component';
import { BatchInfoComponent } from '../batch-info/batch-info.component';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import UserAction from 'src/models/UserAction';
import { DateTimeService } from '../shared/services/datetime.service';
import { Subscription } from 'rxjs';
import { RootScopeService } from '../core/services/root-scope.service';
import { ComponentServices } from '../shared/services/component-services';
import { StorageService } from '../core/services/storage.service';
import { letterIds } from '../models/email-template/sent-email';


@Component({
  selector: 'app-letters-table',
  templateUrl: './letters-table.component.html',
  styleUrls: ['./letters-table.component.css'],
})
export class LettersTableComponent implements OnInit {

  public date_of_letter:string;
  public date_of_upload:string;
  public search_field_letter:string;
  public true_or_false:any;
  public letters_array_const:letterIds[];
  public list_of_letters = []
  public searchParams = <SearchParams>{};
  public deleteParams = <SearchParams>{};
  public batchParams = <BatchParams>{};
  public display_data:any = [];
  public dataSource:string[]
  public response:any
  public lastPage:Boolean
  public firstPage:Boolean = true
  public isSearchActivated:Boolean = true
  public letters_array:letterIds[];
  public isSearchTriggered:Boolean = false;
  public filteredLetters: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public isLoading:Boolean = false
  public isDataLoaded = false;
  public isBatches = false;
  public pageCounter:number = 1;
  public lastSeenKeys: any[] = []
  public batch_upload_date:string|undefined;
  public batch_file_name:string|null;
  public isSearchPopulated:Boolean=false;
  public isNoResults:Boolean=false;
  public isBatchSearchPopulated:Boolean=false;
  public isNoBatchResults:Boolean=false;
  public isBatchSearchPopulated_:Boolean=false;
  public isShowingDuplicates:Boolean = false;
  private first_document_id:string;
  public customerId:string;
  viewBatchDocument:Boolean;
  public enableBatchDelete:Boolean;

  constructor(private apiService: ApiService, private route:ActivatedRoute,public storageService: StorageService, private dateTimeService: DateTimeService, public dialog: MatDialog, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth, private datePipe:DatePipe, private snackBar:MatSnackBar, public rootScope: RootScopeService, public services: ComponentServices,private router: Router) {
    this.services.activeComponent = this;
   }

  ngOnInit(): void {
    this.customerId = this.rootScope.globals.currentCustomer     // default
    this.searchParams = {}
    this.date_of_letter = '';
    this.date_of_upload = '';
    this.isSearchPopulated = false
    this.isShowingDuplicates = false
    this.firstPage = true;
    // there are ~200 letter types we're keeping track of, stored in letters-array.ts
    // this.letters_array = letters
    // this.letters_array_const = letters

    // setting the data on the main page
    // since this is on the instantiation of the component, the two required arguments are null, and the optional SearchParams argument is left empty
    this.getLetterIds(this.customerId);
    this.setData(null, null)
    this.isDataLoaded = true;
    this.enableBatchDelete = this.rootScope.globals.rnav_role == 'Admin'? true: false;
  }
    /**
  * This method returns letterIds
  */
    getLetterIds(customerId:string){
      const getLetterDetailsSubscription: Subscription = this.apiService
      .getLetterIds(customerId)
      .subscribe(response => {
        this.letters_array =  response;
        this.letters_array.sort((a, b) => {
          if (a.letter_id < b.letter_id) {
            return -1;
          }
          if (a.letter_id > b.letter_id) {
            return 1;
          }
          return 0;
        });
        this.letters_array = this.letters_array.filter((item, index, self) =>
          index === self.findIndex(t => t.letter_id === item.letter_id)
        );
        this.letters_array_const=this.letters_array;
      })
    }

  handleSubscription(subscription: Subscription) {
    // this.subscriptions.push(subscription);
  }

  // dynamically filters the letters as the user types in the Letter ID search tab
  filterOptione(event:any) {
   this.letters_array = this.letters_array_const.filter(letter => letter.letter_id.toLowerCase().includes(this.search_field_letter.toLowerCase()))
  }
  //TODO - select customers
  /* public selectCustomer(event:any) {
    this.isLoading = true
    this.searchParams.isSearchingDuplicates = false;
    this.searchParams.batch_id = undefined;
    this.searchParams.duplicates = undefined;
    this.isShowingDuplicates = false
    if (this.isBatches) {
      this.searchBatches(null, null, null, this.searchParams)
    }
    else {
      this.setData(null, null, this.searchParams)
    }
  } */

  // triggered when a batch tile is clicked in batch mode. Updates the global searchParams batch_id field and calls the setData function with the optional
  // searchParams arg set to the searchParams
  public viewDocumentsinBatch(details:any) {
    this.viewBatchDocument = true;
    this.searchParams.batch_id = details.batch_identifier;
    this.setData(null, null, this.searchParams)
  }


  // triggered when the checkbox on the top right corner of a document tile
  // uses optimisitc UI to first update the interface, and then call the API service to mark a document as "reviewed" in DynamoDB
  // once updated, the reviewed document will go to the back of the displayed documents, so documents not yet reviewed are displayed first
  // similarly, when I document is "un-reviewed", the opposite occurs
  public async review(event:Event, entry:any) {
    event.stopPropagation()
    const loggedinUser = await this._oktaAuth.getUser();
    console.log(entry)
    let userAction:UserAction = {
      user: loggedinUser.name!,
      date_of_action: this.dateTimeService.getCurrentDateTime(),
      action: entry.reviewed ? "Unreviewed" : "Reviewed",
      date_of_upload: entry.date_of_upload,
      document_id: entry.document_id,
      item:'Document',
      customer_cd: this.customerId,
      original: entry.original
    }
    await this.apiService.logUserAction(userAction).subscribe((data) => {
      console.log("action logged")
    })
    console.log(userAction)
    for (let i = 0; i < this.response.Items.length; i++) {
      if (this.response.Items[i].document_id == entry.document_id) {
        let date = this.response.Items[i].date_of_upload
        let batch_id = this.response.Items[i].batch_id
        let batch_file_name = encodeURIComponent(this.response.Items[i].batch_file_name)
        await this.apiService.updateReview(entry.document_id, date, (!this.response.Items[i].reviewed).toString(), batch_id, batch_file_name).subscribe((data) => {
          this.response.Items[i].reviewed = !this.response.Items[i].reviewed
        })
      }
    }
  }


// used in batch mode to check if every document in a given batch is reviewed
  public isFullyReviewed(entry:any) {
    if(entry.batch_items.length==0){
      return false
    }
    if (entry.batch_items.length == entry.batch_items_reviewed.length) {
      return true
    }
    return false
  }

  // used in batch mode to display total number of documents
  public documentTotalDisplay(entry:any) {
    if (entry.batch_items.length == 1) return `1 Total Document`
    return `${entry.batch_items.length} Total Documents`
  }
  // used in batch mode to display the total number of documents in a batch which have been reviewed
  public reviewedDocumentsDisplay(entry:any) {
    if (entry.batch_items_reviewed == 1) return `1 Reviewed Documents`
    return `${entry.batch_items_reviewed.length} Reviewed Documents`
  }

  // documents which have been reviewed get a green color, those which haven't, a violet color
  public reviewedBackgroundColor(reviewed:boolean) {
    if (reviewed) {
      return '#009863'
    }
    return '#C799C2'
  }

  letters:any[]

  // sets the response to be displayed
  // the document_id and letter_id can be a string or null. Null means start the search from the beginning of the database
  // Not null means we're starting the search from a previous index, since DynamoDB can only scan 1MB at a time.
  // document_id is the primary key, and letter_id is the sort key
  // searchParams is an optional search parameter
  async setData(document_id:string|null, letter_id:string|null, searchParams?:any, first_document_id?:any) {
    // loading symbol displayed until result returns
    this.isLoading = true
    // main search endpoint in the api service
      await this.apiService.getEntries(document_id, letter_id,this.customerId,searchParams).subscribe((data) => {
        const index = data.Items.findIndex((item:any) => item.document_id === first_document_id);
        if (index > -1) {
          this.response.Items = data.Items.slice(0,index)
          this.response.Count = index
          this.response.LastEvaluatedKey = undefined
          // this.response.LastEvaluatedKey = {
          //   'customer_cd': data.Items[index-1].customer_cd,
          //   'date_of_upload': data.Items[index-1].date_of_upload,
          //   'document_id': data.Items[index-1].document_id
          // }
        }
        else {
          this.response = data
        }
        if(!this.searchParams.batch_id){

          this.viewBatchDocument = false;
        }
        //console.log(this.response);
        // clicking on the blue and white button on the bottom right corner of the documents tab displays only copies of that document
        // if there are no duplicates, it'll display just that document by itself
        if (this.searchParams.duplicates !== undefined) {
          this.isShowingDuplicates = true
        }



        // if Dynamo returns a 'LastEvaluatedKey' in the response, there's still more data, so we're not on the last page
        // the lastPage bool decides whether the 'next' arrow is active
        if (this.response['LastEvaluatedKey']) {
          this.lastPage = false
        }
        else {
          this.lastPage = true
        }

        this.display_data.push(this.response.Items)
        this.dataSource = data['Items']
        let length_of_arr = data['Items'].length

        // if there are no results, we set isNoResults to true, which will display a "No Results" graphic
        if (length_of_arr == 0) {
          this.isNoResults = true
        }
        else {
          this.isNoResults = false
        }

        // at this point, we're done loading, and can get rid of the spinning icon
        this.isLoading = false
        this.isBatches = false
        })
}


  // background color of letter. Can group the letters by first two chars
  // TODO: can add to this depending on what other letters are common/available
  backgroundColor(letter_id:string) {
    if (letter_id == 'SGLODMORM') {
      return '#BD4830'
    }
    if (letter_id.substring(0,2) == 'ML') {
      return '#00C1A1'
    }

    return '#047CB1'
  }

  triggerSearch() {
    this.isSearchTriggered = !this.isSearchTriggered
  }


  // displays table with parsed info via a modal
  // triggered by clicking the eye icon on the bottom of the document tiles
  viewDetails(event:Event, document_id:string) {
    event.stopPropagation()
    this.isLoading = true
    this.apiService.getDocumentData(document_id,this.customerId).subscribe((data) => {
      this.isLoading = false
      const dialogRef = this.dialog.open((DocumentDetailsComponent), {
        width: '60%',
        data: data,
      })
    })
  }

  // displays only the copies of a given document.
  // triggered by clicking the blue and white button on the bottom right of the tile
  // if there's only one copy of the letter (no duplicates), it'll display that document and nothing else
  public async checkDuplicates(event:Event, entry:any) {
    event.stopPropagation()
    this.searchParams = {};
    this.searchParams.isSearchingDuplicates = true
    // for avoiding 'Request Header Fields Too Large' error, limited the header fields to 25 documents.
    if(entry.duplicates.length>25) this.searchParams.duplicates = entry.duplicates.slice(0, 25)
    else  this.searchParams.duplicates = entry.duplicates
    //console.log(this.searchParams.duplicates);
    await this.setData(null, null, this.searchParams)
  }

  public navigateToManualReview(event:Event,entry:any){
    event.stopPropagation()
    this.rootScope.globals.currentDocument = entry;
    this.storageService.setSession('globals', this.rootScope.globals);
    this.router.navigate(['index-expert-details/edit'])
  }

  // deletes an entry. Right now, it's a soft delete, but there's an option for hard delete in delete-component.ts, which is commented out
  // *MAKE SURE TO RUN THAT CODE WHEN HARD DELETING. SINCE DYNAMO IS NOT A RELATIONAL DATABASE, WE HAVE TO UPDATE TWO TABLES SEPERATELY WHEN DELETING ITEMS (WHICH THAT CODE DOES)*
  async deleteEntry(event:Event, document_id:string, letter_id:string, name:string, claim_number:string, duplicates:any, date_of_upload:string, batch_id:string, batch_file_name:string, entry:any) {
    event.stopPropagation()
    const data = {
      event: event,
      document_id: document_id,
      letter_id: letter_id,
      customer_cd: this.customerId,
      name: name,
      claim_number: claim_number,
      duplicates: duplicates,
      date_of_upload: date_of_upload,
      batch_id: batch_id,
      batch_file_name: batch_file_name,
      dialog: this.dialog
    }

    // modal asking if you're sure
    const dialogRef = this.dialog.open((DeleteComponent), {
      width: '30%',
      height: '50%',
      data: data
    })

    // when the user clicks "delete" on the delete modal, this code optimistically deletes the entry from the UI before it gets a response from the server
    // when an entry is deleted, it goes to the back of the display "lineup", even behind reviewed documents
    // it gets a Deleted tag where there's previously a "Reviewed" or "Not Reviewed" tag
    const sub = dialogRef.componentInstance.notifyParent.subscribe((data) => {
      const item = this.response.Items.filter((item: { document_id: any, deleted:any }) => {
        if(item.document_id === data.document_id){
          item.deleted = data.deleted;
        }
      });
    })

    dialogRef.afterClosed().subscribe(() => {
      // unsubscribe onAdd
    });


  }

  notifyUser(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  // different letters give different data
  // depending on what data we get back, we'll display that piece of data on the tile
  // TODO: add conditionals for letters which don't have any of these data points
  public displayDataPoint(entry:any) {
    /* if (entry.hasOwnProperty('claim_number')) {
      return `Claim: ${entry['claim_number']}`
    } */

    if (entry['additional_information'].hasOwnProperty('Insurer Policy Number')) {
      return `Policy Number: ${entry['additional_information']['Insurer Policy Number']['S']}`
    }

    if (entry.hasOwnProperty('document_control_number')) {
      if (entry['document_control_number'].includes("Document Control Number:")) {
        return `Document: ${entry['document_control_number'].substring(24)}`
      }
      return `Document: ${entry['document_control_number']}`
    }

    if (entry.hasOwnProperty("case_identification_number")) {
      if (entry['case_identification_number'].includes("Case Identification Number:")) {
        return `Case ID: ${entry['case_identification_number'].substring(27)}`
      }
      return `Case ID: ${entry['case_identification_number']}`
    }

    if (entry['additional_information'].hasOwnProperty('CRC Recovery ID Number')) {
      return `Recovery ID: ${entry['additional_information']['CRC Recovery ID Number']['S']}`
    }

    // for letters which we haven't seen yet and don't have this data, return nothing, for now
    return ""
  }


  // should the 'reviewed' checkbox be checked by default or not
  public checkedOrNot(entry:any) {
    if (entry.reviewed) {
      return true
    }
    return false
  }

  deleteBatch(entry:any) {
    console.log(entry)
    const dialogRef = this.dialog.open(DeleteBatchComponent, {
      width: '30%',
      height: '50%',
      data: { batch_identifier: entry.batch_identifier, batch_file_name: entry.batch_file_name, date_of_upload: entry.date_of_upload }
    });

    const sub = dialogRef.componentInstance.notifyParent.subscribe((data) => {
      console.log("deleted")
      console.log(this.response.Items)
      this.response.Items = this.response.Items.filter((item:any) => item.batch_identifier !== entry.batch_identifier);
      this.notifyUser("Batch deleted successfully")
        }
  )
  }
  // opens the corresponding PDF preview, stored in S3 bucket
  openPdf(document_id:string, letter_id:string) {
    this.isLoading = true
    this.apiService.getPdfview(document_id).subscribe((data) => {
      this.isLoading = false
      const dialogRef = this.dialog.open(PdfDialogComponent, {
        width: '60%',
        height: '100%',
        position: {right: '0'},
        data: data.pdf,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    })

  }

  // search params are stored in ngModels in the searchParams global object
  // this function gives that object to the setData function to execute the search
  search() {
    if (this.date_of_letter != undefined && this.date_of_letter != '') {
      this.searchParams.date_of_letter = new DatePipe('en').transform(this.date_of_letter!, 'MMMM d, y');
    }

    if (this.date_of_upload != undefined && this.date_of_upload != '') {
      this.searchParams.date_of_upload = new DatePipe('en').transform(this.date_of_upload!, 'YYYY-MM-dd');
    }

    this.setData(null, null, this.searchParams)

    if (Object.keys(this.searchParams).length !== 0) {
      this.isSearchPopulated = true
    }
    this.firstPage = true
  }


  // reset searchParams back to initial state, ie the landing page
  public resetSearch() {
    this.setData(null, null)
    this.searchParams = {}
    this.date_of_letter = '';
    this.date_of_upload = '';
    this.isSearchPopulated = false
    this.isShowingDuplicates = false
    this.firstPage = true;
  }

  // if entry is a duplicate or is soft deleted, the color changes
  public isDuplicateOrDeleted(entry:any) {
    if (entry.deleted || entry.duplicates.length > 1) {
      return true
    }
    return false
  }

  // display text
  public displayDuplicateOrDeleted(entry:any) {
    if (entry.deleted) {
      return "Deleted"
    }

    else if (entry.duplicates.length > 1) {
      return "Duplicate"
    }

    return "Not a Duplicate"
  }

  // toggles between document mode and batch mode
  public async toggle() {
    this.searchParams = {}
    this.firstPage = true
    this.isBatchSearchPopulated = false;
    this.isSearchPopulated = false;
    this.pageCounter = 1;
    if (!this.isBatches) {
      //console.log("toggling...")
      this.isLoading = true;
      this.searchBatches(null, null, null, this.searchParams)
    }
    else {
      this.isLoading = true
      await this.setData(null, null)
    }
    this.isSearchPopulated = false
    this.isShowingDuplicates = false
    this.isShowingDuplicates = false
  }

  // cuts off long batch file names on the tile in document mode
  public batchFileDisplay(batch_name:string) {
    if (batch_name.length < 20) {
      return batch_name
    }
    return `${batch_name.substring(0, 20)}...`
  }

  // when user sets the search in batch mode
  public resetBatchResults() {
    this.isLoading = true;
    this.batchParams = {};
    this.isBatchSearchPopulated_ = false;
    this.batch_upload_date = undefined;
    this.true_or_false = undefined;
    this.batch_file_name = null;
    this.searchBatches(null, null, null, null)
  }

  // cuts off batch file names (in batch mode) which are long enough to run on to the next line
  public displayBatchName(name:string) {
    if (name.length < 25) {
      return name;
    }
    else {
      return `${name.substring(0, 25)}...`
    }
  }


  // search trigger for batch mode
  private searchBatches(identifier:string|null, file_name:string|null, date_of_upload:string|null, searchParams?:any) {
    this.apiService.getBatches(identifier, file_name, date_of_upload, searchParams, this.customerId).subscribe((data) => {
      this.response = data
      if (data['Items'].length == 0) {
        this.isNoBatchResults = true
      }
      else {
        this.isNoBatchResults = false
      }
      if (this.response['LastEvaluatedKey']) {
        this.lastPage = false
      }
      else {
        this.lastPage = true
      }

      if (Object.keys(this.batchParams).length !== 0) {
        if(Object.keys(this.batchParams).length == 1){
          if(this.batchParams.reviewed!=undefined){
            this.isBatchSearchPopulated_ = true
          }
        } else{
          this.isBatchSearchPopulated_ = true
        }
      }
      else {
        this.isBatchSearchPopulated_ = false;
      }

      this.isLoading = false
      this.isBatches = true
    })
  }

  // advances to the next page in batch mode using DynamoDB's LastEvaluated
  public async nextPageBatches() {
    if (this.lastPage) {
      return
    }
    this.setSearchBatches();
    // if we're navigating forward, can't be on the first page
    this.firstPage = false
    this.pageCounter++;

    let batch_identifier = this.response.LastEvaluatedKey.batch_identifier
    let batch_file_name = this.response.LastEvaluatedKey.batch_file_name
    let date_of_upload = this.response.LastEvaluatedKey.date_of_upload
    this.searchBatches(batch_identifier, batch_file_name, date_of_upload, this.batchParams)
    this.lastSeenKeys.push(this.response.LastEvaluatedKey)

  }


  // search functionality for batches
  public parseBatches() {
    this.isLoading = true;
    this.setSearchBatches();
    this.searchBatches(null, null, null, this.batchParams)
  }

  private setSearchBatches() {
    if (this.batch_upload_date != undefined) {
      this.batchParams.date_of_upload = new DatePipe('en').transform(this.batch_upload_date!, 'YYYY-MM-dd')
    }

    if (this.batch_file_name != undefined) {
      this.batchParams.batch_file_name = this.batch_file_name
    }
    if (this.true_or_false != undefined) {
      if (this.true_or_false == 'true') {
        this.batchParams.reviewed = 'true'
      }
      else {this.batchParams.reviewed = 'false'}
    } else{
      this.batchParams.reviewed = undefined
    }
  }


  // converts stored date to more easily readable format
  public convertDate(datetime:string) {
    // let time_display = new Date(datetime)
    // time_display.setHours(time_display.getHours() - 4)
   /*  let time_display = new Date(datetime+" UTC")
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let date = new Date(datetime).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
    var time_ = time_display.toLocaleTimeString();
    let am_or_pm = time_.slice(-2)
    let time_readable = time_.substring(0, time_.length -6)
    let time_return = `${date} at ${time_readable} ${am_or_pm}`
    return time_return */
    return this.datePipe.transform(this.dateTimeService.convertUtcToLocal(datetime), 'MMM d, y \'at\' h:mm a')
  }


  // collapses the search UI
  clearSearch() {
    this.isSearchTriggered = false
  }

  // navigates to previous page in batch mode
  async previousPageBatches() {

    if (this.pageCounter == 1) {
      return
    }

    if (this.firstPage) {
      return
    }
    this.setSearchBatches();
    this.pageCounter--

    this.lastPage = false
    if (this.pageCounter == 1) {
      this.firstPage = true;
      this.searchBatches(null, null, null, this.batchParams)
    }

    else {
      this.lastPage = false
      var batch_identifier;
      var batch_file_name;
      var date_of_upload;
      if (this.lastSeenKeys.length >= 2) {
        batch_identifier = this.lastSeenKeys[this.lastSeenKeys.length - 2].batch_identifier
        batch_file_name = this.lastSeenKeys[this.lastSeenKeys.length - 2].batch_file_name
        date_of_upload = this.lastSeenKeys[this.lastSeenKeys.length - 2].date_of_upload
      }

      else {
        batch_identifier = this.lastSeenKeys[this.lastSeenKeys.length - 1].batch_identifier
        batch_file_name = this.lastSeenKeys[this.lastSeenKeys.length - 1].batch_file_name
        date_of_upload = this.lastSeenKeys[this.lastSeenKeys.length - 1].date_of_upload
      }
      this.lastSeenKeys.pop()
      this.searchBatches(batch_identifier, batch_file_name, date_of_upload, this.batchParams)
    }
  }
  //refreshes the page
  async refresh(){
  this.ngOnInit();
  }
  // navigates to next page in document mode
  async nextPage() {
    console.log(this.response)

    // if this is the last page of content, there is no next page
    if (this.lastPage) {
      return
    }

    if (this.firstPage) {
      this.first_document_id = this.response.Items[0].document_id;
    }


    // if we're navigating forward, can't be on the first page
    this.firstPage = false
    this.pageCounter++;

    let last_seen_id = this.response.LastEvaluatedKey.document_id
    let last_seen_date = this.response.LastEvaluatedKey.date_of_upload
    await this.setData(last_seen_id, last_seen_date, this.searchParams, this.first_document_id)
    this.lastSeenKeys.push(this.response.LastEvaluatedKey)

  }

  // navigates to previous page in document mode
  async previousPage() {
    if (this.pageCounter == 1) {
      return
    }

    if (this.firstPage) {
      return
    }

    this.pageCounter--

    this.lastPage = false
    if (this.pageCounter == 1) {
      this.firstPage = true;
      this.setData(null, null, this.searchParams)
    }

    else {
      this.lastPage = false
      var doc_id;
      var date;
      if (this.lastSeenKeys.length >= 2) {
        doc_id = this.lastSeenKeys[this.lastSeenKeys.length - 2].document_id
        date = this.lastSeenKeys[this.lastSeenKeys.length - 2].date_of_upload
      }

      else {
        doc_id = this.lastSeenKeys[this.lastSeenKeys.length - 1].document_id
        date = this.lastSeenKeys[this.lastSeenKeys.length - 1].date_of_upload
      }
      this.lastSeenKeys.pop()
      this.setData(doc_id, date, this.searchParams)
    }


  }
   /**
   * Function use to initiate the switching customer process.
   * @param custId: Customer Id Selected.
   */
   switchCustomer(custId: string) {
    this.services.InitializeCustomerSwitchingProcess(custId).then(
      () => {
        setTimeout(
          () => {
            this.ngOnInit();
          }, 5);
      }
    );
  }
}
