import { Inject, Injectable } from '@angular/core';
import { Globals } from '../../../models/common/Globals';
import { StorageService } from 'src/app/core/services/storage.service';
import { RootScopeService } from 'src/app/core/services/root-scope.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { jwtDecode, JwtPayload } from 'jwt-decode';

interface RnavAccessToken extends JwtPayload {
  rnav_role?: string;
}


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public storageService: StorageService, public rootScope: RootScopeService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }
  clearCredentials() {
    const promise = new Promise<void>((resolve, reject) => {
      this.rootScope.globals = new Globals();
      this.storageService.destroySession('globals');
      resolve();
    });
    return promise.then(
      () => {
        return Promise.resolve(true);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
  async setCredentials(customerId:string, source:string) {
    let user = await this._oktaAuth.getUser();
    const accesstoken = await this._oktaAuth.getAccessToken();
    const user_name = user.given_name + ' ' + user.family_name;
    if (accesstoken) {
      const decoded = jwtDecode<RnavAccessToken>(accesstoken);

      if (!decoded || !('rnav_role' in decoded)) {
        console.warn(`Attribute "${'rnav_role'}" not found in token`);
        this.rootScope.globals.rnav_role = "Guest";
      } else {
        console.log(`user "${user_name}" logged in with role "${decoded['rnav_role']}"`);
        this.rootScope.globals.rnav_role = decoded['rnav_role'];
      }
    } else {
      console.error('Access token is undefined');
    }

    this.rootScope.globals.currentUserName = user_name;
    const promise = new Promise<void>((resolve, reject) => {
      this.rootScope.globals.currentCustomer = customerId;
      this.rootScope.globals.currentCustomerName = this.rootScope.globals.customers.filter(x => x.customer_cd === customerId)[0].customer_name;
      this.rootScope.globals.menuItems = this.rootScope.globals.customers.filter(x => x.customer_cd === customerId)[0].menu_items;
      this.rootScope.globals.source=source;
      this.storageService.setSession('globals', this.rootScope.globals);
      resolve();
    });
    return promise.then(
      () => {
        return Promise.resolve(true);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

}
